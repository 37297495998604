var generic = generic || {};
var site = site || {};

(function ($) {
  var $body = $('body');
  function getParameterByName(parameterName) {
    var result = null,
      tmp = [];
    var items = location.search.substr(1).split('&');

    for (var index = 0; index < items.length; index++) {
      tmp = items[index].split('=');
      if (tmp[0].toLowerCase() === parameterName.toLowerCase()) {
        result = decodeURIComponent(tmp[1]);
      }
    }

    return result;
  }

  site.emailSignup = {
    templateContainer: $('.site-email-signup'),

    initEmailSignup: function (emailSignUpTmplContainter, isOverlayEmailSignup) {
      var $emailContainerNodes = emailSignUpTmplContainter ? emailSignUpTmplContainter : this.templateContainer;
      var $emailSubmit = $('.js-site-email-signup__submit');
      var $sitewideBannerWrapper = $('.js-content-block-sitewide-banner__content-wrapper');
      var $disclosureTrigger = $('.js-content-block-sitewide-banner-trigger');
      var hideFooterEmail;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailWrapperNode = $emailContainerNode.closest('.site-email-signup__wrapper');
        var $emailForm = $emailContainerNode.is('form')
          ? $emailContainerNode
          : $('form', $emailContainerNode);
        var $emailHeader = $emailContainerNode.find('.site-email-signup__title');
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $defaultTermsChkd = $('.js-default-terms-checked', $emailContainerNode);
        var $countryChooser = $('.js-country-id', $emailContainerNode);
        var $countryField = $('input[name="COUNTRY_ID"]', $emailContainerNode);
        var $selectBoxLabel = $('.js-country-id .selectBox-label', $emailContainerNode);
        var $formContent = $('.site-email-signup__content', $emailContainerNode);
        var $lastSource = $('input[name="LAST_SOURCE"]', $emailContainerNode);
        var $formElements = ['pc_email_address', 'country_id', 'pc_email_promotions'];
        // If GET param exists, specify last_source
        var lastSource = getParameterByName('source');

        if (lastSource) {
          $lastSource.val(lastSource);
        }

        function _open() {
          hideFooterEmail = $body.hasClass('content-block-sitewide-banner--enabled');

          if (!hideFooterEmail) {
            $emailWrapperNode.addClass('site-email-signup__wrapper--active');
            $formContent.stop(true, true).slideDown(600);
          }
        }

        function _close() {
          hideFooterEmail = $body.hasClass('content-block-sitewide-banner--enabled');

          if (!hideFooterEmail) {
            $emailWrapperNode.removeClass('site-email-signup__wrapper--active');
            _resetFields();
            $formContent.stop(true, true).slideUp(600);
          }
        }

        function _resetFields() {
          for (var i = 0; i < $formElements.length; i++) {
            $('.js-' + $formElements[i] + '_error_message').addClass('hidden');
            $('.js-' + $formElements[i] + '_node').removeClass('error');
          }
        }

        if (isOverlayEmailSignup) {
          $emailInput.removeClass('adpl--processed');
          Drupal.behaviors.adaptivePlaceholders.setupDOM($emailInput);
        } else {
          // Show email signup
          if ($('body').hasClass('front')) {
            $(window).on(
              'scroll',
              _.debounce(function () {
                if ($(window).scrollTop() + $(window).height() === $(document).height()) {
                  _open();
                } else if (
                  $(window).scrollTop() + $(window).height() <
                  $(document).height() - 320
                ) {
                  _close();
                }
              }, 100)
            );
          }

          // When clicked open the form
          $emailInput.add($mobileNumberInput).on('focus', _open);

          $emailHeader.on('click', function (e) {
            e.preventDefault();
            _close();
          });
        }

        $countryChooser.on('change', function () {
          var $selectedOption = $(this).find('option:selected')[0];

          if ($selectedOption.hasAttribute('data-language-id')) {
            $('#language_id').val($($selectedOption).attr('data-language-id'));
          }
        });

        $emailForm.off('submit.signup').on('submit.signup', function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          _resetFields();

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
            params.PC_EMAIL_PROMOTIONS = 1;
          }

          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          $emailContainerNode.addClass('loading');

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                // Close active utility items
                $('.js-gnav-util__content').addClass('hidden');
                $emailInput.val('').blur();
                if ($countryChooser.find('option:first').val() === '') {
                  $countryChooser.val('');
                  $countryField.val('');
                  $selectBoxLabel.text($countryChooser.find('option:selected').text());
                }
                $mobileNumberInput.val('');
                $formContent.slideUp(600);
                $termsAndConditions
                  .find('input')
                  .attr('checked', $defaultTermsChkd.length ? true : false);
                $.colorbox({
                  html: $emailSuccess.html(),
                  width: '80%',
                  height: 'auto',
                  reposition: true
                });
              }
              $emailContainerNode.removeClass('loading');
              $body.addClass('confirm-signin-popup').removeClass('content-block-sitewide-banner--enabled');
              $emailSubmit.closest($sitewideBannerWrapper).children($disclosureTrigger).trigger('click');
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();

              for (var i = 0; i < errorObjectsArray.length; i++) {
                var fieldName = errorObjectsArray[i].key
                  ? errorObjectsArray[i].key.match(/\w*.(\w*)./)
                  : '';

                if (fieldName && fieldName[1]) {
                  var $node = $('.js-' + fieldName[1].toLowerCase() + '_node', $emailContainerNode);
                  var $message = $(
                    '.js-' + fieldName[1].toLowerCase() + '_error_message',
                    $emailContainerNode
                  );

                  $node.addClass('error');
                  $message.removeClass('hidden');
                }
              }
              if (isOverlayEmailSignup) {
                var $cbox = $('#colorbox');

                $cbox.colorbox.resize();
              }
            }
          });
        });
      });
    },
    initOverlayEmailSignup: function () {
      // call cbox_complete only the js-overlay exist and ask whether by email signup overlay
      var $jsOverlay = $('.js-overlay');

      if ($jsOverlay.length > 0) {
        $(document).on('cbox_complete', function () {
          var $cbox = $('#colorbox');
          var $overlayContainer = $('#cboxContent');
          var $emailSignUpOverlay = $overlayContainer.find('.site-email-signup__wrapper');
          var $isOverlayEmailSignup = 1;

          if (!$emailSignUpOverlay) {
            return null;
          }
          $cbox.colorbox.resize();
          var $emailSignUpTmplContainter = $emailSignUpOverlay.find('.site-email-signup');

          site.emailSignup.initEmailSignup($emailSignUpTmplContainter, $isOverlayEmailSignup);
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      site.emailSignup.initOverlayEmailSignup();
    }
  };
})(jQuery);
